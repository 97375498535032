<script lang="ts" setup>
import { XMarkIcon } from '@heroicons/vue/24/outline'
import copy from 'copy-to-clipboard'
import ls from 'localstorage-slim'
import { Ref, Teleport, computed, ref } from 'vue'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import MegaModal from '@ankor-io/blocks/components/modals/MegaModal.vue'
import SimpleAlertModal from '@ankor-io/blocks/components/modals/SimpleAlertModal.vue'
import { modalHelper } from '@ankor-io/blocks/components/modals/modalHelper'
import { showAwaitToast } from '@ankor-io/blocks/components/toast'
import ListGrid from '@ankor-io/blocks/layouts/ListGrid.vue'
import ListTable from '@ankor-io/blocks/layouts/ListTable.vue'
import { Trip } from '@ankor-io/common/trip/Trip'
import { SolidCollection, SolidDocumentAdd, SolidSearch, SolidTable } from '@ankor-io/icons/solid'

import SearchNoResultsMoon from '@/assets/search-no-results-moon.svg'
import SearchNoResultsPresentation from '@/assets/search-no-results-presentation.svg'
import ProposalPreview from '@/components/ProposalPreview.vue'
import VisibleObserver from '@/components/VisibleObserver.vue'
import { useProposalService } from '@/services/proposal/ProposalService'
import { useStowageService } from '@/services/stowage/StowageService'
import { epochAsLabel } from '@/utils/date'

import PresentationsActionsKebab from './PresentationsActionsKebab.vue'
import PresentationsActionsPreview from './PresentationsActionsPreview.vue'
import Tags from './Tags.vue'

const UNTITLED_PRESENTATION = 'Untitled Presentation'

// the columns in the table.
const columns = [
  { label: 'Name', field: 'name', type: 'string', classes: { th: 'w-2/5', td: 'truncate' } },
  { label: 'Locations Include', field: 'locations', type: 'string', classes: { th: 'w-2/5', td: 'truncate' } },
  { label: 'Last Edited', field: 'dateEdited', type: 'string', classes: { th: 'w-32', td: 'whitespace-nowrap' } },
  { label: 'Date Created', field: 'dateCreated', type: 'string', classes: { th: 'w-32', td: 'whitespace-nowrap' } },
  { label: 'Tags', field: 'tags', type: 'string', classes: { th: 'w-32' } },
  { label: 'Routes', field: 'numRoutes', type: 'number', classes: { th: 'w-16' } },
  { label: '', field: 'actions', type: 'number', classes: { th: 'w-14' } },
]

// the data in the table -- the dirty one has "null" values for tombstoned items.
let dirtyRiver: Ref<any[]> = ref([])
const river = computed(() => dirtyRiver.value.filter((item) => !!item))

//
// During the "transform items" step of the Aloglia search API,
// accumulate the results in the river array.
const accumulateSearchResults = (items: any[], { results }: any): object => {
  //  ** IMPORTANT **
  //  There is no guarantee that this function will be called only once
  //    for each call to refineNext() or via an Algolia HTTP call. See:
  //   - https://github.com/algolia/vue-instantsearch/issues/707#issuecomment-1361526922
  //

  // reset river to empty array if it's the first page
  if (results.page === 0) {
    dirtyRiver.value = []
  }

  items.forEach((hit, i) => {
    let offset = results.page * results.hitsPerPage + i

    if (ls.get<string>(`tombstone::${hit.uri}`) !== null) {
      dirtyRiver.value[offset] = null
      return
    }

    // set the hit to the expected row position aligning to the position of the hit.
    dirtyRiver.value[offset] = {
      uri: hit.objectID,
      hero: hit.hero,
      name: hit.line_1 || UNTITLED_PRESENTATION,
      tags: hit.tags,
      locations: Array.from(new Set([...hit.line_3, ...hit.line_4]))
        .sort()
        .join(', '),
      dateCreated: epochAsLabel(hit.createdAt),
      dateEdited: epochAsLabel(hit.lastModified),
      numRoutes: hit.line_3?.length,
    }
  })

  // return the clean river (used by algolia lib)
  return river
}

//
// Modal helpers
const previewModalHelper = modalHelper()
const deleteModalHelper = modalHelper()
const stowage = useStowageService()
const proposalService = useProposalService()
const requestDeleteDocument = async (uri: string, name: string) => {
  // hide the modal
  deleteModalHelper.hide()
  // delete & show toast
  return showAwaitToast(
    stowage.deleteDocument(uri),
    `Deleting <em>${name}</em>...`,
    `<em>${name}</em> deleted.`,
    `<em>${name}</em> could not be deleted.`,
  ).then(() => {
    // tombstone the proposal locally
    ls.set(`tombstone::${uri}`, 'true')
    // clear it from the river results
    const idx = dirtyRiver.value.indexOf(dirtyRiver.value.find((item) => item && item.uri === uri))
    dirtyRiver.value[idx] = null
  })
}

//
// Share helpers
const share = (domain: string, uri: string, name: string) => {
  // hide the modal
  previewModalHelper.hide()
  // share & show toast
  showAwaitToast(
    proposalService.share(uri),
    `Creating link to share <em>${name}</em>...`,
    `Link created and copied to your clipboard.`,
    `<em>${name}</em>, shareable link could not be copied.`,
  ).then((trip: Trip) => {
    copy(`${domain}/${trip.bookingId}/proposal`)
  })
}
</script>
<template>
  <ais-configure filters="type:proposal AND NOT tags:tombstone" hitsPerPage="36" />

  <!-- Delete modal -->
  <Teleport defer to="body">
    <!-- delete modal -->
    <SimpleAlertModal
      v-if="deleteModalHelper.isVisible()"
      :model="deleteModalHelper.model"
      @confirm="(model: any) => [deleteModalHelper.hide(), requestDeleteDocument(model.uri, model.name)]"
      @dismiss="deleteModalHelper.hide()"
      buttonText="Yes, Delete"
    >
      <template #title="{ modelValue }">
        <h3>{{ modelValue.title }}</h3>
      </template>
      <template #default="{ modelValue }">
        <div class="space-y-2.5 text-center text-sm text-gray-500">
          <p>You are about to permanently delete</p>
          <p class="font-bold">{{ modelValue.name }}</p>
          <p>Are you sure you want to proceed?</p>
        </div>
      </template>
    </SimpleAlertModal>
  </Teleport>

  <!-- Preview modal -->
  <Teleport defer to="body">
    <MegaModal
      v-if="previewModalHelper.isVisible()"
      :model="previewModalHelper.model"
      @dismiss="previewModalHelper.hide()"
    >
      <template #header="{ modelValue }">
        <div class="flex justify-between">
          <div>
            <h4>{{ modelValue.name }}</h4>
            <div class="flex items-center gap-x-2">
              <p v-if="modelValue.dateEdited" class="text-sm">Last edited: {{ modelValue.dateEdited }}</p>
              <Tags v-if="modelValue.tags" :tags="modelValue.tags" />
            </div>
          </div>
          <div class="flex items-center gap-x-4">
            <div class="hidden lg:block h-6 w-px bg-gray-900/10" aria-hidden="true"></div>
            <PresentationsActionsPreview
              :uri="modelValue.uri"
              @shareClick="share($config.WAVE_URL, modelValue.uri, modelValue.name)"
              @deleteClick="
                () =>
                  deleteModalHelper.show({
                    title: 'Delete this presentation?',
                    uri: modelValue.uri,
                    name: modelValue.name,
                  })
              "
            />

            <!-- FIXME: add this X to the modal so that it does not scroll away -->
            <button
              type="button"
              class="transition-colors rounded-md text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-500"
              @click="previewModalHelper.hide()"
            >
              <span class="sr-only">Close</span>
              <XMarkIcon class="size-7" aria-hidden="true" />
            </button>
          </div>
        </div>
      </template>

      <template #default="{ modelValue }">
        <div class="min-h-24 overflow-y-auto">
          <ProposalPreview :uri="modelValue.uri" />
        </div>
      </template>
    </MegaModal>
  </Teleport>

  <div class="grid grid-cols-1 gap-4">
    <div class="flex flex-col gap-4 sm:flex-row justify-between items-start sm:items-center">
      <h3 class="sm:h-10">Presentations</h3>
      <RouterLink
        :to="{ name: 'trips-editor-presentations-new' }"
        class="w-full sm:w-auto justify-center transition-colors focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center text-white bg-primary-600 hover:bg-primary-700 focus:ring-primary-300 dark:focus:ring-primary-800"
        target="_blank"
      >
        <SolidDocumentAdd class="size-5 mr-2 self-center" />
        New Presentation
      </RouterLink>
    </div>

    <div class="w-full flex flex-col md:flex-row justify-between items-baseline gap-2 pb-4 min-h-11">
      <div class="w-full flex flex-col sm:flex-row items-start sm:items-center gap-3">
        <div class="w-full sm:w-auto flex gap-x-2 items-center">
          <!-- nunununununununun -->
          <!--   Category Box    -->
          <!-- nunununununununun -->
          <ais-menu-select attribute="tags">
            <template v-slot="{ refine }">
              <select
                class="h-11 bg-gray-50 z-20 border-1 border-gray-300 rounded-lg focus:ring-0 focus:border-gray-500 focus-within:border-gray-500"
                @change="refine(($event.currentTarget! as HTMLInputElement).value)"
              >
                <option class="" value="">All Presentations</option>
                <option class="" value="custom">My Presentations</option>
                <option class="" value="Design Studio">Design Studio</option>
              </select>
            </template>
          </ais-menu-select>

          <!-- nunununununununun -->
          <!--    Search Box     -->
          <!-- nunununununununun -->
          <ais-search-box
            placeholder="Search for anything..."
            submit-title="Submit"
            reset-title="clear"
            :autofocus="true"
            :show-loading-indicator="false"
            :class-names="{
              'ais-SearchBox': 'h-11 w-full',
              'ais-SearchBox-form':
                'p-3 h-full flex items-center gap-x-2 relative rounded-lg border border-gray-300 focus:border-gray-500 focus-within:border-gray-500 dark:bg-gray-700',
              'ais-SearchBox-input':
                'bg-transparent order-2 h-full w-full z-20 outline-none border-none focus:ring-0 focus:border-0 focus:outline-0 placeholder-gray-500 text-gray-500',
              'ais-SearchBox-reset': 'hidden',
              'ais-SearchBox-loadingIcon': 'hidden',
            }"
          >
            <template v-slot:submit-icon>
              <SolidSearch
                class="order-first solid-search z-20 pointer-events-none w-5 h-5 stroke-2 fill-general-text-body"
              />
            </template>
          </ais-search-box>
        </div>

        <!-- nunununununununun -->
        <!--       Views       -->
        <!-- nunununununununun -->
        <div class="border-b border-gray-200 dark:border-gray-700">
          <ul class="flex flex-nowrap -mb-px text-center text-gray-500 dark:text-gray-400">
            <li class="me-2">
              <RouterLink
                :to="{ path: $route.fullPath, query: { view: undefined } }"
                :class="[
                  $route.query.view !== 'card'
                    ? 'inline-flex items-center justify-center p-2 text-primary-600 border-b-2 border-primary-600 rounded-t-lg active dark:text-primary-500 dark:border-primary-500 group'
                    : 'inline-flex items-center justify-center p-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group',
                ]"
              >
                <SolidTable class="size-4 me-2" />Table
              </RouterLink>
            </li>
            <li class="me-2">
              <RouterLink
                :to="{ path: $route.fullPath, query: { view: 'card' } }"
                :class="[
                  $route.query.view === 'card'
                    ? 'inline-flex items-center justify-center p-2 text-primary-600 border-b-2 border-primary-600 rounded-t-lg active dark:text-primary-500 dark:border-primary-500 group'
                    : 'inline-flex items-center justify-center p-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group',
                ]"
              >
                <SolidCollection class="size-4 me-2" />Cards
              </RouterLink>
            </li>
          </ul>
        </div>
      </div>

      <!--  nunununununununun  -->
      <!--        Stats        -->
      <!--  nunununununununun  -->
      <ais-stats class="hidden xl:block text-sm min-w-36 text-right">
        <template v-slot="{ nbHits }">
          <template v-if="nbHits == 0">
            <span class="hidden">No Presentations</span>
          </template>
          <template v-else-if="nbHits == 1"> 1 Presentation </template>
          <template v-else> {{ nbHits }} Presentations </template>
        </template>
      </ais-stats>
    </div>
  </div>

  <!-- nunununununununun -->
  <!--  Search Results   -->
  <!-- nunununununununun -->
  <ais-infinite-hits :transform-items="accumulateSearchResults">
    <template v-slot="{ refineNext, isLastPage, results }">
      <div id="searchResults-top"></div>

      <!-- Getting Started and No Results-->
      <!-- add '?showGetStarted=1' to show that screen manually -->
      <template v-if="!results.nbHits || $route.query.showGetStarted">
        <slot name="no-results">
          <template v-if="!results.query && !results.params.includes('facetFilters')">
            <!-- Show the getting started diagram. -->
            <div class="grid grid-cols-1 gap-4 justify-items-center pt-16">
              <div class="h-[33vh]">
                <img :src="SearchNoResultsPresentation" alt="No results found" class="h-full" />
              </div>
              <h4>Looks like you have no presentations yet!</h4>
              <div>Get started by creating your first presentation.</div>
              <div>
                <RouterLink
                  :to="{ name: 'trips-editor-presentations-new' }"
                  class="transition-colors focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center text-white bg-primary-600 hover:bg-primary-700 focus:ring-primary-300 dark:focus:ring-primary-800"
                  target="_blank"
                >
                  <SolidDocumentAdd class="size-5 mr-2 self-center" />
                  New Presentation
                </RouterLink>
              </div>
            </div>
          </template>
          <template v-else>
            <!-- No results. -->
            <div class="grid grid-cols-1 gap-4 justify-items-center pt-16">
              <div class="h-[33vh]"><img :src="SearchNoResultsMoon" alt="No results found" class="h-full" /></div>
              <h4>No results.</h4>
              <div>Sorry! We could not find anything to match your query.</div>
            </div>
          </template>
        </slot>
      </template>

      <!-- Show results -->
      <template v-else>
        <!-- ... in grid cards -->
        <template v-if="$route.query.view === 'card'">
          <ListGrid :keyField="'uri'" :items="river">
            <template #item="{ item }">
              <!-- Presentation Card -->
              <div>
                <!-- Presentation Card Image + Badge -->
                <button
                  type="button"
                  class="w-full aspect-[1.44] relative hover:scale-105 focus:ring-4 focus:outline-none focus:ring-primary-300 cursor-pointer rounded-lg transition-transform duration-500 ease-in-out"
                  aria-label="Preview Presentation"
                  @click="
                    previewModalHelper.show({
                      uri: item.uri,
                      name: item.name,
                      tags: item.tags,
                      dateEdited: item.dateEdited,
                    })
                  "
                >
                  <div v-if="item.tags" class="absolute top-2 left-2">
                    <Tags :tags="item.tags" />
                  </div>
                  <AssetViewerPlaceholder
                    class="w-full object-cover object-center shadow-md rounded-lg"
                    :url="item.hero"
                    :widthDescriptors="['320w']"
                  />
                </button>
                <!-- Presentation Card Text Section-->
                <div class="relative">
                  <h6 class="mt-4 mb-2 mr-8 line-clamp-2">
                    {{ item.name }}
                  </h6>
                  <p v-if="item.dateEdited" class="text-sm">Last edited: {{ item.dateEdited }}</p>
                  <div class="absolute top-0 right-0">
                    <PresentationsActionsKebab
                      :row="item"
                      @shareClick="share($config.WAVE_URL, item.uri, item.name)"
                      @deleteClick="
                        () =>
                          deleteModalHelper.show({ title: 'Delete this presentation?', uri: item.uri, name: item.name })
                      "
                    />
                  </div>
                </div>
              </div>
            </template>
            <template #after>
              <template v-if="!isLastPage">
                <VisibleObserver @visible="refineNext" />
              </template>
              <template v-else="isLastPage">
                <div class="mt-8 text-center text-sm">You have reached the end of the results!</div>
              </template>
            </template>
          </ListGrid>
        </template>
        <!-- ... in a table -->
        <template v-else>
          <ListTable
            rowKey="uri"
            class="w-full table-fixed"
            :columns="columns"
            :rows="river"
            :classes="{ thead: 'top-16', tr: 'cursor-pointer' }"
            @rowClick="
              ({ row, field }) =>
                'actions' !== field &&
                previewModalHelper.show({
                  uri: row.uri,
                  name: row.name,
                  tags: row.tags,
                  dateEdited: row.dateEdited,
                })
            "
          >
            <!-- column: NAME -->
            <template #name="{ row }">
              <div class="flex items-center gap-x-2">
                <div class="min-w-20 max-w-20 h-12">
                  <AssetViewerPlaceholder
                    class="object-cover object-center w-full h-full rounded"
                    :url="row.hero"
                    :widthDescriptors="['320w']"
                  />
                </div>
                <span class="font-semibold truncate">{{ row.name }}</span>
              </div>
            </template>
            <!-- column: TAGS -->
            <template #tags="{ row }">
              <Tags :tags="row.tags" />
            </template>
            <!-- column: ACTION MENU -->
            <template #actions="{ row }">
              <PresentationsActionsKebab
                :row="row"
                @shareClick="share($config.WAVE_URL, row.uri, row.name)"
                @deleteClick="
                  () => deleteModalHelper.show({ title: 'Delete this presentation?', uri: row.uri, name: row.name })
                "
              />
            </template>
            <template #after>
              <template v-if="!isLastPage">
                <VisibleObserver @visible="refineNext" />
              </template>
              <template v-else="isLastPage">
                <div class="mt-8 text-center text-sm">You have reached the end of the results!</div>
              </template>
            </template>
          </ListTable>
        </template>
      </template>
    </template>
  </ais-infinite-hits>
</template>
