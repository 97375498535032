<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { computed } from 'vue'

const props = defineProps<{
  name: string | null
}>()

const emits = defineEmits<{
  (e: 'logout'): void
}>()

const initial = computed(() => {
  return props.name?.charAt(0)
})
</script>
<template>
  <Menu as="div" class="relative inline-block">
    <MenuButton class="-m-1.5 flex items-center p-1.5">
      <span class="sr-only">Open user menu</span>
      <div
        v-if="props.name"
        class="relative inline-flex items-center justify-center size-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600"
      >
        <span class="font-medium text-sm uppercase" data-test="initials">
          {{ initial }}
        </span>
      </div>
      <div
        v-else
        class="relative size-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600"
        data-test="placeholder-icon"
      >
        <svg
          class="absolute size-10 text-gray-400 -left-1"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path>
        </svg>
      </div>
      <span class="hidden lg:flex lg:items-center">
        <!-- span v-if="props.name" class="ml-4 text-sm/6 font-semibold text-gray-900" aria-hidden="true" data-test="label">
          {{ props.name }}
        </span -->
        <ChevronDownIcon class="ml-2 size-5 text-gray-400" aria-hidden="true" />
      </span>
    </MenuButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
      >
        <MenuItem v-slot="{ active }">
          <a
            href="/auth/logout"
            @click="$emit('logout')"
            class="w-full block px-3 py-1 text-sm/6 text-gray-900"
            :class="{ 'bg-gray-50 outline-none': active }"
          >
            Logout
          </a>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>
