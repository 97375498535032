<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import '@ankor-io/common/tailwindcss/base.scss'

import Loading from '@/components/Loader.vue'

const AppContext = defineAsyncComponent(() => import('./AppContext.vue'))
</script>
<template>
  <Suspense>
    <template #fallback>
      <Loading class="h-screen" />
    </template>
    <AppContext>
      <main class="trips bg-white dark:bg-gray-900">
        <RouterView name="main" />
      </main>
    </AppContext>
  </Suspense>
</template>
<style></style>
