<script setup lang="ts">
import { Ref, inject, onMounted, ref } from 'vue'
import { RouteLocationNormalizedLoaded, Router, useRoute, useRouter } from 'vue-router'

import AccountMenu from '@ankor-io/blocks/components/AccountMenu.vue'
import { showErrorToast, showInfoToast } from '@ankor-io/blocks/components/toast'
import Banner from '@ankor-io/blocks/layouts/Banner.vue'
import OneColumnWithHeader from '@ankor-io/blocks/layouts/OneColumnWithHeader.vue'

import Loader from '@/components/Loader.vue'
import { AuthenticationContext } from '@/iam/types'

const props = defineProps<{
  uri?: string
  items?: string
}>()

const bannerOpen: Ref<boolean> = ref(false)
const authenticationContext: AuthenticationContext = inject('authenticationContext')!
const route: RouteLocationNormalizedLoaded = useRoute()
const router: Router = useRouter()

onMounted(() => {
  if (route.name === 'trips-editor-presentations-new') {
    createNewPresentation()
  } else if (route.name === 'trips-editor-presentations-duplicate' && props.uri) {
    duplicate(props.uri)
  }
})

// Creates a new presentation and navigates to the edit page
const createNewPresentation = async () => {
  showInfoToast('Creating new presentation...')

  const token = await authenticationContext.getToken()
  const res: Response = await fetch('/api/presentation/new', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  if (res.ok) {
    const { uri }: { uri: string } = await res.json()
    router.replace({ name: 'trips-editor-presentations-edit', params: { uri } })
  } else {
    showErrorToast('Failed to create new presentation')
    console.debug('Failed to create new presentation', res)
  }
}

// Duplicates a presentation and navigates to the edit page
const duplicate = async (fromUri: string) => {
  showInfoToast('Duplicating presentation...')

  const token = await authenticationContext.getToken()
  const res: Response = await fetch(`/api/presentation/duplicate/${fromUri}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  if (res.ok) {
    const { uri }: { uri: string } = await res.json()
    router.replace({
      name: 'trips-editor-presentations-edit',
      params: { uri },
      query: { addItem: route.query?.addItem },
    })
  } else {
    showErrorToast('Failed to duplicate presentation')
    console.debug('Failed to duplicate presentation', res)
  }
}
</script>
<template>
  <div>
    <Banner v-if="bannerOpen" @close="bannerOpen = false">
      <!-- p>Meet us at <strong>Cairns</strong>!</p -->
    </Banner>
    <OneColumnWithHeader
      :class-names="
        bannerOpen
          ? {
              content: 'h-[calc(100vh-2.75rem)]',
            }
          : undefined
      "
    >
      <template #header>
        <div
          class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8"
        >
          <div class="flex flex-1 justify-end gap-x-4 self-stretch lg:gap-x-6">
            <div class="flex items-center gap-x-4 lg:gap-x-6">
              <!-- Separator -->
              <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true"></div>

              <!-- Profile dropdown -->
              <AccountMenu :name="$authenticationContext.getUser()?.given_name" />
            </div>
          </div>
        </div>
      </template>
      <template #default>
        <div v-if="$route.name === 'trips-editor-presentations-new'"><Loader /></div>
        <div v-if="$route.name === 'trips-editor-presentations-duplicate'"><Loader /></div>
        <div v-if="$route.name === 'trips-editor-presentations-edit'">
          <div>Edit {{ props.uri }}</div>
          <div v-if="$route.query?.addItem">Item to add {{ $route.query.addItem }}</div>
          <Loader />
        </div>
      </template>
    </OneColumnWithHeader>
  </div>
</template>
